.border-none[data-v-2450a697]:before {
  display: none;
}
.border-none[data-v-2450a697]:after {
  display: none;
}
.flex-fix[data-v-2450a697] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-2450a697]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-2450a697] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-2450a697] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-2450a697] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-2450a697] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-2450a697] {
  *zoom: 1;
}
.clear-fix[data-v-2450a697]:before,
.clear-fix[data-v-2450a697]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.info-item[data-v-2450a697] {
  font-size: 0.32rem;
  color: #333;
  background: #fff;
  width: 100%;
  height: 1rem;
  padding: 0 0.24rem;
  margin-top: 0.2rem;
}
.info-item input[data-v-2450a697]::-webkit-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.info-item input[data-v-2450a697]::-moz-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.info-item input[data-v-2450a697]:-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.info-item input[data-v-2450a697]::-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.info-item input[data-v-2450a697]::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.pay-sty[data-v-2450a697] {
  width: 60%;
  height: 80%;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  border-radius: 1rem;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  border: 0;
}
.orderBtn[data-v-2450a697] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.orderBtn .btn-item[data-v-2450a697] {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  width: 2rem;
  height: 0.68rem;
  border-radius: 0.68rem;
  text-align: center;
  line-height: 0.68rem;
  font-size: 0.28rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  margin-right: 0.3rem;
}
.orderBtn .bg[data-v-2450a697] {
  background: var(--main-color);
  color: #fff;
}
.padding[data-v-2450a697] {
  margin-left: -0.3rem;
}
.PopUp[data-v-2450a697] {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  z-index: 999999;
  bottom: 0%;
  height: 2000%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.PopUp .bot-mobile[data-v-2450a697] {
  width: 80%;
  position: absolute;
  height: 3rem;
  bottom: 4rem;
  left: 0.5rem;
  text-align: center;
  line-height: 80px;
  background-color: #fff;
  margin-left: 0.3rem;
  border-radius: 10px;
}
.PopUp .bot-mobile img[data-v-2450a697] {
  width: 0.5rem;
  height: 0.5rem;
  float: right;
  margin: 0.2rem;
}
.PopUp .bot-mobile .service-phone[data-v-2450a697] {
  font-size: 19px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  position: absolute;
  margin-top: 0.5rem;
}
.PopUp .bot-mobile .service-phone span[data-v-2450a697] {
  color: #000;
}
.PopUp .bot-mobile .servicepnone[data-v-2450a697] {
  display: inline-block;
  width: 2rem;
  height: 0.5rem;
  font-size: 16px;
  color: #000;
  margin-top: 0.25rem;
}
.PopUp .bot-mobile .dial[data-v-2450a697] {
  left: 0rem;
  width: 1.5rem;
}
.PopUp .bot-mobile .dial[data-v-2450a697],
.PopUp .bot-mobile .cancel[data-v-2450a697] {
  position: absolute !important;
  font-size: 15px;
  border-radius: 0 0 0 10px;
  line-height: 0.8rem !important;
  height: 0.8rem;
  width: 3rem;
  line-height: 0.5rem;
  text-align: center;
  color: #fff;
  top: 2.2rem !important;
  background: var(--main-color);
}
.PopUp .bot-mobile .cancel[data-v-2450a697] {
  left: 50% !important;
  width: 50%;
  border-radius: 0 0 10px 0;
  background: #f1f1f1;
  color: var(--main-color);
}
