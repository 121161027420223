.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.info-item {
  font-size: 0.32rem;
  color: #333;
  background: #fff;
  width: 100%;
  height: 1rem;
  padding: 0 0.24rem;
  margin-top: 0.2rem;
}
.info-item input::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.pay-sty {
  width: 60%;
  height: 80%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 1rem;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  border: 0;
}
.orderBtn {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
}
.orderBtn .btn-item {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  width: 2rem;
  height: 0.68rem;
  border-radius: 0.68rem;
  text-align: center;
  line-height: 0.68rem;
  font-size: 0.28rem;
  box-sizing: border-box;
  background: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  margin-right: 0.3rem;
}
.orderBtn .bg {
  background: var(--main-color);
  color: #fff;
}
.padding {
  margin-left: -0.3rem;
}
.PopUp {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  z-index: 999999;
  bottom: 0%;
  height: 2000%;
  flex-shrink: 0;
}
.PopUp .bot-mobile {
  width: 80%;
  position: absolute;
  height: 3rem;
  bottom: 4rem;
  left: 0.5rem;
  text-align: center;
  line-height: 80px;
  background-color: #fff;
  margin-left: 0.3rem;
  border-radius: 10px;
}
.PopUp .bot-mobile img {
  width: 0.5rem;
  height: 0.5rem;
  float: right;
  margin: 0.2rem;
}
.PopUp .bot-mobile .service-phone {
  font-size: 19px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  margin-top: 0.5rem;
}
.PopUp .bot-mobile .service-phone span {
  color: #000;
}
.PopUp .bot-mobile .servicepnone {
  display: inline-block;
  width: 2rem;
  height: 0.5rem;
  font-size: 16px;
  color: #000;
  margin-top: 0.25rem;
}
.PopUp .bot-mobile .dial {
  left: 0rem;
  width: 1.5rem;
}
.PopUp .bot-mobile .dial,
.PopUp .bot-mobile .cancel {
  position: absolute !important;
  font-size: 15px;
  border-radius: 0 0 0 10px;
  line-height: 0.8rem !important;
  height: 0.8rem;
  width: 3rem;
  line-height: 0.5rem;
  text-align: center;
  color: #fff;
  top: 2.2rem !important;
  background: var(--main-color);
}
.PopUp .bot-mobile .cancel {
  left: 50% !important;
  width: 50%;
  border-radius: 0 0 10px 0;
  background: #f1f1f1;
  color: var(--main-color);
}
